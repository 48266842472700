@import '../../../../scss/theme-bootstrap';

// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.

.signin-block {
  width: 100%;
  text-align: $ldirection;
  max-width: 485px;
  margin: 0 auto;
  .error_messages {
    margin: 0 0 10px 0;
  }
  &__forms {
    margin: 0 0 10px 0;
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &--register {
    @include pie-clearfix;
    max-width: 970px;
    .signin-block__main {
      float: $ldirection;
      width: 46%;
      margin-#{$rdirection}: 4%;
    }
    .signin-block__footer {
      float: $rdirection;
      width: 46%;
      margin-#{$ldirection}: 4%;
    }
  }
  &__form--signin {
    display: none;
  }
  &__main,
  &__footer {
    @include pie-clearfix;
  }
  &__title {
    @include h2;
    margin-top: 0;
  }
  &__field-wrapper {
    display: block;
    margin: 0 auto 17px auto;
    &--password-show {
      display: block;
      margin: -10px 0 20px;
      .label-content {
        padding-#{$ldirection}: 2px;
      }
    }
  }
  input[type='submit'] {
    border-radius: 10px;
  }
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    width: 100%;
    border-radius: 10px;
  }
  &__field {
    &--first-name,
    &--last-name {
      @include breakpoint($landscape-up) {
        width: 49%;
        margin-bottom: 4px;
      }
    }
    &--first-name {
      @include breakpoint($landscape-up) {
        float: $ldirection;
      }
    }
    &--last-name {
      @include breakpoint($landscape-up) {
        float: $rdirection;
      }
    }
    &--email {
      @include breakpoint($landscape-up) {
        clear: $ldirection;
      }
    }
    &--password-show {
      display: block;
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__forgot-password {
    display: block;
    color: $color-black;
    margin: 15px 0 0 0;
    @include breakpoint($landscape-up) {
      display: inline;
      &:before {
        content: '|';
        display: inline-block;
        color: $color-black;
        @include swap_direction(margin,0 6px 0 -2px);
      }
    }
    .signin-block__footer & {
      display: block;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &__submit {
    float: $rdirection;
  }
  &__toggle {
    @include basic-text-reset;
    margin-bottom: 20px;
    text-transform: uppercase;
    &__cta {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__checkboxes {
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      margin-bottom: 15px;
    }
    label {
      @include basic-text-reset;
    }
    &--show-password {
      margin: -8px 0 20px 0;
      @include breakpoint($landscape-up) {
        margin: -10px 0 25px 0;
      }
    }
  }
  &__checkbox {
    display: block;
    margin: 0 0 10px 0;
    position: relative;
    padding-#{$ldirection}: 23px;
    input[type='checkbox'],
    input[type='radio'] {
      position: absolute;
      top: 2px;
      #{$ldirection}: 0;
    }
  }
  .sign-in-component {
    &__form-smashcash-header-image {
      margin-#{$ldirection}: -8px;
    }
    &__form-loyalty-text {
      margin-bottom: 2em;
      text-transform: uppercase;
    }
    &__birthday-label {
      @include h3;
      display: inline-block;
      margin-top: 0.75em;
      vertical-align: top;
    }
    &__loyalty-program {
      .signin-block__checkboxes {
        margin-top: 2em;
      }
    }
  } // .sign-in-component
} // .signin-block

// Authenticated user
.signin-block__authed {
  display: none;
  .elc-user-state-logged-in & {
    display: block;
  }
  &__title {
    // Wait for js to render the title text as it's raw, user-entered
    // mustache.
    display: none;
    &--rendered {
      display: block;
    }
    &-first,
    &-second {
      margin: 0;
      display: inline-block;
    }
    &-welcome {
      margin: 0 0 10px 0;
      display: inline-block;
    }
  }
  &__signout-link {
    display: block;
    padding-#{$ldirection}: 20px;
    @include breakpoint($landscape-up) {
      display: inline-block;
      padding: 0;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  &__content {
    li {
      margin: 0 0 8px 0;
    }
  }
  &__loyalty {
    @include breakpoint($landscape-up) {
      margin-bottom: 20px;
      &__logo {
        display: block;
        max-width: 150px;
        margin-bottom: 5px;
      }
      &__non-member__register {
        margin-bottom: 5px;
      }
    }
  }
}

// auth states visibility
.elc-user-state-anonymous {
  .hide_anon {
    display: none;
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}

// signing_thanks.tmpl
.signin-thanks {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px 20px 30px;
  @include breakpoint($landscape-up) {
    padding-#{$ldirection}: 30px;
    padding-#{$rdirection}: 30px;
  }
  &__header {
    @include h2;
    margin: 20px 0 15px;
  }
}
